<template>
    <div class="page">
        <div class="home">
            <div class="header">
                <div class="logo">
                    <img src="../assets/logo.png"/>
                </div>
                <div class="convert" @click="showConvertModal">
                    <img src="../assets/code.png"/>
                    请输入权益码
                </div>
            </div>
            <div class="banner">
                <img src="../assets/kv.png"/>
            </div>
            <div class="title" v-if="convertList.length > 0">
                <img src="../assets/top_title.png"/>
            </div>
            <div class="convertList">
                <ConvertItem v-for="item in convertList" :key="item.id" :item="item"/>
            </div>
            <div class="bottom">
                <BottomCom/>
            </div>
        </div>
        <div class="footer">
            <span @click="goHome">我的权益</span>
            <span @click="goOrder">兑换中心</span>
        </div>
        <ConvertModal :modal="convertModal" v-on:closeModal="closeConvertModal"/>
    </div>
</template>

<script>
    import BottomCom from "../components/BottomCom";
    import {getConvertList} from "../api/data";
    import _ from "lodash";
    import {Toast} from "vant";
    import ConvertItem from "../components/ConvertItem";
    import ConvertModal from "../components/ConvertModal";

    export default {
        name: "Home",
        components: {ConvertModal, ConvertItem, BottomCom},
        data: function () {
            return {
                convertModal: false,
                convertList: []
            }
        },
        async beforeMount() {
            await this.queryConvertList();
        },
        methods: {
            showConvertModal() {
                this.convertModal = true;
            },
            async goHome() {
                await this.queryConvertList();
            },
            async goOrder() {
                await this.$router.push('/Order')
            },
            async closeConvertModal(status) {
                this.convertModal = false;
                if (status) {
                    await this.queryConvertList();
                }
            },
            async queryConvertList() {
                let param = {};
                let res = await getConvertList(param);
                if (_.toInteger(res.code) === 1) {
                    this.convertList = res.data.data;
                    // this.convertList = [];
                } else {
                    Toast.fail(res.msg);
                }
            }
        }
    };
</script>

<style scoped>
    .home {
        display: flex;
        flex-direction: column;
        width: 690px;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding: 24px 24px 144px 24px;
    }

    .home .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 642px;
        margin-top: 30px;
    }

    .left {
        background: none;
    }

    .home .header .logo {
        width: 298px;
        height: 68px;
        overflow: hidden;
        margin-left: 20px;
    }

    .home .header .logo img {
        width: 100%;
    }

    .home .header .convert {
        width: 220px;
        height: 60px;
        background: #272E30;
        border-radius: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 400;
        color: #FFFFFF;
    }

    .home .header .convert img {
        width: 28px;
        height: 28px;
        margin-right: 4px;
    }

    .home .banner {
        width: 642px;
        height: 412px;
        margin-top: 50px;
    }

    .home .banner img {
        width: 100%;
    }

    .home .title {
        width: 291px;
        height: 45px;
        margin-top: 50px;
    }

    .home .title img {
        width: 100%;
    }

    .home .convertList {
        width: 642px;
        margin-top: 50px;
        flex: 1;
    }


    .home .bottom {
        width: 642px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .page .footer {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        width: 690px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }


    .page .footer span {
        flex: 1;
        margin: 0 20px;
        height: 80px;
        border-radius: 40px;
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page .footer span:first-child {
        border: 2px solid #222529;
        color: #212429;
    }

    .page .footer span:last-child {
        border: 2px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        color: #E2BE93;
    }
</style>
