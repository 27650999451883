<template>
    <div class="convertItem" @click="goConvertDetail(item.id)">
        <div class="left">
            <img :src="item.image" :alt="item.name"/>
        </div>
        <div class="body">
            <div class="top">{{item.name}}<span>{{getStatusName(item.type)}}</span></div>
            <div class="desc">{{item.description}}</div>
            <div class="times">有效期至: {{item.endtime}}</div>
        </div>
        <div class="type">{{item.introduction}}</div>
    </div>
</template>

<script>
    export default {
        name: "ConvertItem",
        props: ['item'],
        methods: {
            getStatusName(type) {
                let typeValue = _.toInteger(type);
                switch (typeValue) {
                    case 1:
                        return '待领取';
                    case 2:
                        return '已选择';
                    case 3:
                        return '已领取';
                    default:
                        return '未知';
                }
            },
            goConvertDetail(id) {
                this.$router.push({name: 'ConvertDetail', query: {id: _.toString(id)}});
            }
        }
    }
</script>

<style scoped>
    .convertItem {
        width: 642px;
        height: 228px;
        background: url("../assets/goog_bg.png") no-repeat left top;
        background-size: 100% auto;
        margin-bottom: 25px;
        position: relative;
        box-sizing: border-box;
        padding: 25px 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }

    .convertItem .left {
        width: 143px;
        height: 143px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .convertItem .left img {
        width: 100%;
    }

    .convertItem .body {
        margin-left: 20px;
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 168px;
        overflow: hidden;
    }

    .convertItem .body .top {
        width: 100%;
        height: 38px;
        font-size: 27px;
        font-weight: 500;
        color: #E2BE93;
    }

    .convertItem .body .top span {
        border: 1px solid #D9AA89;
        background: linear-gradient(118deg, #38363D 0%, #1F1F2B 100%);
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        font-size: 19px;
        font-weight: 500;
        line-height: 26px;
        color: #E2BE93;
        margin-left: 10px;
    }

    .convertItem .body .desc {
        width: 100%;
        margin-top: 10px;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
        color: #B19485;
    }

    .convertItem .body .times {
        margin-top: 30px;
        width: 100%;
        font-size: 19px;
        font-weight: 400;
        line-height: 26px;
        color: #E8C8AF;
    }

    .convertItem .type {
        position: absolute;
        left: 0;
        top: 0;
        width: 110px;
        height: 38px;
        background: linear-gradient(118deg, #E2BE93 0%, #DEBA90 100%);
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 19px;
        font-weight: bold;
        color: #211F27;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
