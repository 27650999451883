<template>
    <van-popup class="model" :close-on-click-overlay="false" @click-overlay="closeModal" v-model="modal">
        <div class="body">
            <div class="title">请输入您的权益码领取权益</div>
            <div class="group">
                <input type="text" placeholder="请输入权益码" v-model="convertCode"/>
            </div>
            <div class="group">
                <input type="text" placeholder="请输入图形验证码" v-model="verify"/>
                <img @click="changeVerify" :src="captchaUrl" class="verifyImg">
            </div>
            <div class="button" @click="submitConvert">下一步</div>
        </div>
        <img class="close" src="../assets/close.png" alt="关闭" @click="closeModal">
    </van-popup>
</template>

<script>
    import {Toast} from 'vant';
    import dayJs from 'dayjs';
    import config from "../config"
    import {equityExchange} from "../api/data";
    import _ from "lodash";

    export default {
        name: "ConvertModal",
        props: ['modal'],
        data() {
            return {
                captchaUrl: '',
                convertCode: '',
                verify: '',
            }
        },
        beforeMount() {
            this.changeVerify();
        },
        methods: {
            async submitConvert() {
                if (this.convertCode == null || this.convertCode == '') {
                    Toast.fail("请先输入权益码");
                    return false;
                }
                if (this.verify == null || this.verify == '') {
                    Toast.fail("请输入图形验证码");
                    return false;
                }
                let params = {
                    yzm: this.verify,
                    barcode: this.convertCode,
                };
                let res = await equityExchange(params);
                if (_.toInteger(res.code) == 1) {
                    this.verify = '';
                    this.convertCode = '';
                    this.$emit('closeModal', true)
                } else {
                    this.changeVerify();
                    Toast.fail(res.msg);
                }
            },
            changeVerify() {
                this.verify = '';
                let baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro;
                let timestamp = dayJs().unix();
                let token = localStorage.getItem("token");
                let captchaUrl = `${baseUrl}login/yzm?token=${token}&timestamp=${timestamp}`;
                this.captchaUrl = captchaUrl;
            },
            closeModal() {
                this.verify = '';
                this.convertCode = '';
                this.$emit('closeModal', false);
            },
        }
    }
</script>

<style scoped>
    .model {
        background: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .model .body {
        width: 612px;
        height: 561px;
        background: #FFFFFF;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 40px;
    }

    .model .body .title {
        width: 100%;
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
        color: #171A1D;
        text-align: center;
        margin: 30px 0;
    }

    .model .body .group {
        width: 100%;
        height: 100px;
        background: #F5F6F8;
        border-radius: 8px;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px 20px;
    }

    .model .body .group input {
        flex: 1;
        border: 0;
        background: none;
    }


    .model .body .group .verifyImg {
        width: 141px;
        height: 55px;
    }

    .model .body .group input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #646464;
        font-size: 30px;
    }

    .model .body .group input::-moz-placeholder {
        /* WebKit browsers */
        color: #646464;
        font-size: 30px;
    }

    .model .body .group input::-moz-placeholder {
        /* WebKit browsers */
        color: #646464;
        font-size: 30px;
    }

    .model .body .group input:-ms-input-placeholder {
        color: #646464;
        font-size: 30px;
    }

    .model .body .button {
        margin-top: 60px;
        height: 80px;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        border-radius: 8px;
        font-size: 30px;
        font-weight: 500;
        color: #E2BE93;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .model .close {
        width: 74px;
        height: 74px;
        margin-top: 60px;
        overflow: hidden;
    }
</style>

